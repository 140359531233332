/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unnecessary-type-arguments */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';

export interface IEventGoalProps{
    PercentageAchieved: string;
    Goal: string;
}

export class EventGoalClass extends React.Component<IEventGoalProps, {}> {
    constructor(props: IEventGoalProps){
        super(props);
    }

    componentDidMount(){

    }

    render(): JSX.Element {
        const {PercentageAchieved, Goal} = this.props;
        return(
            <div>
                <div className={`col-md-12 progressBar`}>
                    <div className={`completedProgress`} style={{"width": `${PercentageAchieved}`}}>
                    </div>
                </div>
                <h3 className={`eventGoalHeading col-md-12`}>
                    <span className={`goalCompleted`}>{PercentageAchieved} </span>
                    <span className={`totalGoal`}>of {Goal} funds raised</span>
                </h3>
            </div>
        )
    }
}

export default EventGoalClass;